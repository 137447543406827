import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const symbolStyle = {
  color: "#999999",
  paddingLeft: "13px"
}

const Donate = () => (
  <Layout>
    <SEO title="Donate" />
    <h1>Donate</h1>
    <p>Please help donate. Any small amount helps local pet fostering organizations in Southern California.</p>
    <div>
      <table>
        <tr>
          <th>Name</th>
          <th>Our Address</th>
        </tr>
        <tr>
          <td>Bitcoin <span style={symbolStyle}>BTC</span></td>
          <td>bc1qrmzxvxa8jwxvxvxu2x4vnj35gyllfay7q0hq7f</td>
        </tr>
        <tr>
          <td>Ethereum  <span style={symbolStyle}>ETH</span></td>
          <td>0xAe1C03144d661e135F0a301c5864216cAbAD3e42</td>
        </tr>
        <tr>
          <td>Stellar  <span style={symbolStyle}>XLM</span></td>
          <td>GDTPOUSGC3ME2RS47HOMWJZIB7KYNA26NV3JZKWDSOWVJPCPYSPDE5Z3</td>
        </tr>
      </table>
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Donate
